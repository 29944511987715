.modal-content {
  border-radius: 0;
}

.data-container {
  position: relative;
  /*min-height: 50vh;*/
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  background-image: url('../src/assets/loading.gif');
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}

.pointer {
  cursor: pointer;
}

.starting {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  background-image: url('../src/assets/loading.gif');
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000;
}