.modal-content {
  border-radius: 0;
}

.data-container {
  position: relative;
  /*min-height: 50vh;*/
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  background-image: url(/static/media/loading.c26ef5f3.gif);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}

.pointer {
  cursor: pointer;
}

.starting {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  background-image: url(/static/media/loading.c26ef5f3.gif);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000;
}
.sxRcRPCPJtcW5F0M8i6A0 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._1xnLwi_PKI5tXdUi3Po0Ft {
  height: 100%;
}
._29QwGgV9o96Pd8oaeDSy4o {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._3oy0gIHHGAFgIyKC36ZRUO {
  min-height: 100vh;
}
._2OKIMuF6VZXHcxWygksJFN {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.NgDVjt5bI5AY6wJ8kBg5Y {
  min-height: 100vh;
}
._1xAk0L79hKSn1GCu1YcYA7 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._1eteU36FOP5_E4z2JyXOof {
  min-height: 100vh;
}
._1-YdYes-Fgg4cr3AMbhLC5 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tVMFYqGrERz-0kFbJTPKw {
  align-items: center;
  display: flex;
}

._3jWWwMv8TZ4lVJRkZeQKRw {
  flex-grow: 1;
}

._3X2G6UGvLxS8I0Bpov9ZRQ {
  display: flex;
}

._3y5iMSrJIF37ohpewbRA51 {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}

._3tjcpHIgu4yjVnahRInc0f {
  position: absolute;
  top: 0;
  left: 0;
}

._3tjcpHIgu4yjVnahRInc0f > tbody>tr:first-child>td {
  border-top: none;
}

.D_c74diQ1zVfs8JUuXyiW {
  line-height: 16px;
}

._11EFgYqON1_qLztAZCSnEd {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}
.CR0icHjvbBI3F6quFFVHD {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

._2AEOCvI7F3GQAOLllM5C7S {
  align-items: center;
  display: flex;
}

.MCzDaxlEONLYKkeqxZNVY {
  flex-grow: 1;
}

._29EG1ANx9SkiHOWoddymRN {
  display: flex;
}

._3z9XAMpNPsqC2DCWgu4N3x {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}

.ISH-G066MImwjHLAaf48e {
  position: absolute;
  top: 0;
  left: 0;
}

.ISH-G066MImwjHLAaf48e > tbody>tr:first-child>td {
  border-top: none;
}

._1nScR7-RyY18p9b40R4FU3 {
  line-height: 16px;
}

._2vRVgHESjB3l_lR6_qeDcC {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}
