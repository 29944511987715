:local(.bg) {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:local(.title) {
  align-items: center;
  display: flex;
}

:local(.middle) {
  flex-grow: 1;
}

:local(.middle-card) {
  display: flex;
}

:local(.item-container) {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}

:local(.item-row) {
  position: absolute;
  top: 0;
  left: 0;
}

:local(.item-row > tbody>tr:first-child>td) {
  border-top: none;
}

:local(.item) {
  line-height: 16px;
}

:local(.sell-list) {
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}